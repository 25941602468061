import React from 'react';
import './Schedule.scss';



export default function Schedule() {
    return (
        <section id="schedule" className="schedule">
        <h2>Otevírací doba</h2>
        
          <table data-aos="fade-up" className="table-schedule">
            <tr>
              <td>Pondělí-Čtvrtek</td>
              <td>8:00 - 17:00</td>
            </tr>
            <tr>
              <td>Pátek</td>
              <td>8:00 - 13:00</td>
            </tr>
            <tr>
              <td>Sobota</td>
              <td>Dle objednání</td>
            </tr>
          </table>
        </section>
    );
}