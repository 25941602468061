export const aboutMarcela = [
    {year:2019, 
        event: ["BLONDE STYLE - Wella",
        "WORK SESSION - Mario Cverna - Wella",
        "WIN IN STYLE - Wella",
        "ÚČESOVÉ TRENDY - Podzim-Zima - Framesi",
        "COLOR by Wella II.",
        "STŘIHY - Studio Wella"]},
    {year: 2018, event: ["SPOLEČENSKÉ VÝČESY - Fanola",
    "BLONDE by Wella"]},
    {year: 2015, event: ["VÝČESY - Fanola",
    "COLOR - Wella",
    "BLOND COLOR - Wella"]}
]

export const aboutRenata = [
    {year:2019, 
        event: ["BLONDE STYLE - Wella",
        "WORK SESSION - Mario Cverna - Wella",
        "WIN IN STYLE - Wella",
        "ÚČESOVÉ TRENDY - Podzim-Zima - Framesi",
        "COLOR by Wella II.",
        "STŘIHY - Studio Wella"]},
    {year: 2018, event: ["SPOLEČENSKÉ VÝČESY - Fanola",
    "BLONDE by Wella"]},
    {year: 2015, event: ["TOP STYLIST - Wella"]}
]