import React, { useEffect } from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Introduction from './components/Introduction/Introduction';
import IntroImgGrid from './components/Introduction/IntroImage/IntroImgGrid';
import Place from './components/Place/Place';
import { Gallery } from './components/Gallery/Gallery';
import About from './components/About/About';
import Schedule from './components/Schedule/Schedule';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {
  
  
  const windowWidth = window.innerWidth;
  let smallImage = true;
  smallImage = windowWidth < 992 ? smallImage = true : smallImage = false;
  
  useEffect(()=>{
    Aos.init({ duration: 1000 });
  },[])
  

  return (
    <div className="App">
      <Header />
      <div className="wrapper">
        <Introduction />
        <IntroImgGrid smallImage={smallImage} />
        <Place smallImage={smallImage}/>
        <Schedule />
        <Gallery smallImage={smallImage}/>
        <About />
        <Contact />
      </div>
      <Footer />
      
    </div>
  );
}

export default App;
