import React from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

 class GoogleMap extends React.Component {
    render(){
        return (
            <div data-aos="fade-up" id="google-map">
                <Map 
                    google={this.props.google} 
                    zoom={18} 
                    initialCenter={{
                        lat: 49.466785,
                        lng: 17.108758
                    }}
                    >
                    <Marker onClick={this.onMarkerClick} name={'Current location'} />
                    <InfoWindow onClose={this.onInfoWindowClose}>
                        {/* <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div> */}
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}



export default GoogleApiWrapper({
    apiKey: ("AIzaSyBYTbO9AoNmtFavmTDFTL3BKtb7e2iDGc0")
  })(GoogleMap);