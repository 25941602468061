import React from 'react';

export default function IntroImage(){
    return (
        <img 
            className="intro-bg"
            src="./images/introImage.svg"
            alt="IntroBg"
        />

    )};