import React from 'react';
import './Contact.scss';



export default function Contact() {
    return (
        <section id="contact" >
            <h2>Kontakt</h2>
            <div data-aos="fade-up" className="contact">
                <div>
                    <h3>Marcela Štefáková</h3>
                    <a className="btn" href="tel:+420607205590">607 205 590</a>
                </div>
                <div>
                    <h3>Renata Robešová</h3>
                    <a className="btn" href="tel:+420736488087">736 488 087</a>
                </div>
            </div>
        </section>
    );
}