import React from 'react';
import './Place.scss';
import Image from 'react-image-webp';
import GoogleMap from './GoogleMap';

export default function Place(props){

    const small = props.smallImage ? '-small' : '';
    

    return (

        <section id="place">
            <h2>Kde nás najít</h2>
            <div data-aos="fade-up" className="place-title">
                
                    <h3>Stanislava Manharda 39<br />PROSTĚJOV</h3>
                
                    <a className="btn" href="https://www.google.cz/maps/place/RM+Salon/@49.4668123,17.1065806,17z/data=!4m13!1m7!3m6!1s0x47125741c97536f5:0xf324e4c5daab01b2!2sStanislava+Manharda+1363%2F39,+796+01+Prost%C4%9Bjov!3b1!8m2!3d49.4668123!4d17.1087693!3m4!1s0x4712571d0effb765:0x2dd5429a56489dc!8m2!3d49.4667176!4d17.1087847">
                        Otevřít v Google Maps
                    </a>
            </div>
            <div className="place-container">
                <GoogleMap />
                <div data-aos="fade-up" className="container-img-place">
                    <Image id="place-img1" className="place-img place-img1" src={`./images/placeImg1${small}.jpg`} webp={`./images/placeImg1${small}.webp`}  alt="placeImg1" />
                    <Image id="place-img2" className="place-img place-img2" src={`./images/placeImg2${small}.jpg`}  webp={`./images/placeImg2${small}.webp`}  alt="placeImg2" />
                </div>
            </div>
        </section>
    ); 
    }