import React from 'react';
import './Carousel.scss';
import Image from 'react-image-webp';


export class Carousel extends React.Component {

    
    render(){
    const { close, prevSlide, nextSlide, current, kindOfImages, jpg, webp} = this.props;
    const img = kindOfImages[current];
    
    


        return (
            <div className="carousel">
            <a href="#gallery" className="btn-close">
                <img onClick={close}  src="./icons/cancel.svg" alt="close" />
            </a>
            <img 
                src="./icons/arrowleft.svg" 
                className="arrow-left img" 
                onClick={prevSlide} 
                alt="arrow-left"

            />
            <img 
                src="./icons/arrowright.svg" 
                className="arrow-right img" 
                onClick={nextSlide} 
                alt="arrow-right" 
            />
            <Image 
                className="active-pic main-pic image" 
                src={img[jpg]} 
                webp={img[webp]} 
                alt={img.alt} 
            />

                  {/* {console.log(kindOfImages)}  */}
        </div>
        )
    }
}