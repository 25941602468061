import React from 'react';
import './About.scss';
import Certificate from './Certificate';

export default function About() {
    return (
        <section id="about" className="about">
        <h2>O nás</h2>
        <p data-aos="fade-up">
            V našem kadeřnickém 
            salonu pracujeme s kosmetikou
             značky Wella, Sebastian, 
            Sebman, Nioxin a Eugene Perma
        </p>

        <Certificate />
        
        </section>
    );
}