import React from 'react';
import './Gallery.scss';
import { imagesOfColoring,imagesOfMen,imagesOfWedding } from '../../data/Img';
import { Carousel } from '../Carousel/Carousel';
import Image from 'react-image-webp';

export class Gallery extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpened: false,
            kindOfImages: 'imagesOfMen',
            current: 0,
            length: null,
            
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
    }

    
    handleClose(){
        this.setState({
            isOpened: false,
        });
    }

    handleClick(e){
        
        this.setState({
            isOpened: true,
            kindOfImages: e.target.value,
            current: 0
        });
    }

    prevSlide(){
        let current = this.state.current === 0 ? this.state.length - 1 : this.state.current - 1;
        this.setState({current: current});
    }
    
    nextSlide(){
        let current = this.state.current === this.state.length - 1 ? 0 : this.state.current + 1;
         this.setState({current: current});
    }

    componentDidUpdate(){
        const kind = this.state.kindOfImages;

        switch(kind){
            case "imagesOfColoring":
                this.setState({
                            kindOfImages: imagesOfColoring,
                            length: imagesOfColoring.length,
                        });
            break;
            case "imagesOfMen":
                this.setState({
                            kindOfImages: imagesOfMen,
                            length: imagesOfMen.length,
                        });
            break;
            case "imagesOfWedding":
                this.setState({
                            kindOfImages: imagesOfWedding,
                            length: imagesOfWedding.length,
                        });
            break;
        }
       
        }

    

   
    render() {

        const isOpened = this.state.isOpened ? 'block' : 'none';
        const prevSlide = this.prevSlide;
        const nextSlide = this.nextSlide;
        const current = this.state.current;
        const close = this.handleClose;
        
        const jpg = this.props.smallImage ? 'srcjpg_small' : 'srcjpg';
        const webp = this.props.smallImage ? 'srcwebp_small' : 'srcwebp';

        const kindOfImages = this.state.kindOfImages;
        const arrayOfImg = Array.from(kindOfImages);
        const mapImg = arrayOfImg.map((img, index ) => 
            <Image 
                key={index} 
                onClick={() => this.setState({current: index})}
                className={index === current ? 'smaller-pic image img-border' : 'smaller-pic image'} 
                src={img[jpg]} 
                webp={img[webp]} 
                alt={img.alt} 

            />
        ); 

        return (
        <section  id="gallery" >
            <h2>Galerie</h2>
            <div data-aos="fade-up" className="gallery">
                <a href="#div-carousel"> <button value='imagesOfColoring' onClick={this.handleClick} className="btn btn-gallery">Barva</button></a>
                <a href="#div-carousel"> <button value='imagesOfMen' onClick={this.handleClick} className="btn btn-gallery">Muži</button></a>
                <a href="#div-carousel"> <button value='imagesOfWedding' onClick={this.handleClick} className="btn btn-gallery">Společenské účesy</button></a>
            </div>
            <div id="div-carousel" style={{ display: isOpened }}>
                <Carousel 
                    close={close}
                    prevSlide={prevSlide} 
                    nextSlide={nextSlide} 
                    current={current} 
                    kindOfImages={kindOfImages}
                    webp={webp}
                    jpg={jpg} />
                <div className="img-under-img">
                    {mapImg}
                </div>
            </div>
        </section>
        
        );
    }

    
}