import React from 'react';
import Logo from '../Logo/Logo';
import './Introduction.scss';
import IntroImage from './IntroImage/IntroImage';


export default function Introduction() {

    return (
        
        <section id="intro" className="intro">
        <div className="container-intro">

            <Logo/>
            <span className="intro-text">
            Kadeřnický salon Renaty Robešové a Marcely Štefákové
            </span>
            <a className="btn intro-button-link" href="#contact">
                    Objednat se
            </a>
        </div>
            <IntroImage/>
        </section>
    );
}