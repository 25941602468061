export const imagesOfColoring = [
    {
        srcwebp: "./gallery/coloring/1.webp",
        srcwebp_small: "./gallery/coloring/1-small.webp",
        srcjpg: "./gallery/coloring/1.jpg",
        srcjpg_small: "./gallery/coloring/1-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/2.webp",
        srcwebp_small: "./gallery/coloring/2-small.webp",
        srcjpg: "./gallery/coloring/2.jpg",
        srcjpg_small: "./gallery/coloring/2-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/3.webp",
        srcwebp_small: "./gallery/coloring/3-small.webp",
        srcjpg: "./gallery/coloring/3.jpg",
        srcjpg_small: "./gallery/coloring/3-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/4.webp",
        srcwebp_small: "./gallery/coloring/4-small.webp",
        srcjpg: "./gallery/coloring/4.jpg",
        srcjpg_small: "./gallery/coloring/4-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/5.webp",
        srcwebp_small: "./gallery/coloring/5-small.webp",
        srcjpg: "./gallery/coloring/5.jpg",
        srcjpg_small: "./gallery/coloring/5-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/6.webp",
        srcwebp_small: "./gallery/coloring/6-small.webp",
        srcjpg: "./gallery/coloring/6.jpg",
        srcjpg_small: "./gallery/coloring/6-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/7.webp",
        srcwebp_small: "./gallery/coloring/7-small.webp",
        srcjpg: "./gallery/coloring/7.jpg",
        srcjpg_small: "./gallery/coloring/7-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/8.webp",
        srcwebp_small: "./gallery/coloring/8-small.webp",
        srcjpg: "./gallery/coloring/8.jpg",
        srcjpg_small: "./gallery/coloring/8-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/9.webp",
        srcwebp_small: "./gallery/coloring/9-small.webp",
        srcjpg: "./gallery/coloring/9.jpg",
        srcjpg_small: "./gallery/coloring/9-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/10.webp",
        srcwebp_small: "./gallery/coloring/10-small.webp",
        srcjpg: "./gallery/coloring/10.jpg",
        srcjpg_small: "./gallery/coloring/10-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/11.webp",
        srcwebp_small: "./gallery/coloring/11-small.webp",
        srcjpg: "./gallery/coloring/11.jpg",
        srcjpg_small: "./gallery/coloring/11-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/12.webp",
        srcwebp_small: "./gallery/coloring/12-small.webp",
        srcjpg: "./gallery/coloring/12.jpg",
        srcjpg_small: "./gallery/coloring/12-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/13.webp",
        srcwebp_small: "./gallery/coloring/13-small.webp",
        srcjpg: "./gallery/coloring/13.jpg",
        srcjpg_small: "./gallery/coloring/13-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/14.webp",
        srcwebp_small: "./gallery/coloring/14-small.webp",
        srcjpg: "./gallery/coloring/14.jpg",
        srcjpg_small: "./gallery/coloring/14-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/15.webp",
        srcwebp_small: "./gallery/coloring/15-small.webp",
        srcjpg: "./gallery/coloring/15.jpg",
        srcjpg_small: "./gallery/coloring/15-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/16.webp",
        srcwebp_small: "./gallery/coloring/16-small.webp",
        srcjpg: "./gallery/coloring/16.jpg",
        srcjpg_small: "./gallery/coloring/16-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/17.webp",
        srcwebp_small: "./gallery/coloring/17-small.webp",
        srcjpg: "./gallery/coloring/17.jpg",
        srcjpg_small: "./gallery/coloring/17-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/18.webp",
        srcwebp_small: "./gallery/coloring/18-small.webp",
        srcjpg: "./gallery/coloring/18.jpg",
        srcjpg_small: "./gallery/coloring/18-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/19.webp",
        srcwebp_small: "./gallery/coloring/19-small.webp",
        srcjpg: "./gallery/coloring/19.jpg",
        srcjpg_small: "./gallery/coloring/19-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/20.webp",
        srcwebp_small: "./gallery/coloring/20-small.webp",
        srcjpg: "./gallery/coloring/20.jpg",
        srcjpg_small: "./gallery/coloring/20-small.jpg",
        alt:"Coloring"
    },
    {
        srcwebp: "./gallery/coloring/21.webp",
        srcwebp_small: "./gallery/coloring/21-small.webp",
        srcjpg: "./gallery/coloring/21.jpg",
        srcjpg_small: "./gallery/coloring/21-small.jpg",
        alt:"Coloring"
    }
]

export const imagesOfMen = [
    {
        srcwebp: "./gallery/men/1.webp",
        srcwebp_small: "./gallery/men/1-small.webp",
        srcjpg: "./gallery/men/1.jpg",
        srcjpg_small: "./gallery/men/1-small.jpg",
        alt:"Men"
    },
    {
        srcwebp: "./gallery/men/2.webp",
        srcwebp_small: "./gallery/men/2-small.webp",
        srcjpg: "./gallery/men/2.jpg",
        srcjpg_small: "./gallery/men/2-small.jpg",
        alt:"Men"
    },
    {
        srcwebp: "./gallery/men/3.webp",
        srcwebp_small: "./gallery/men/3-small.webp",
        srcjpg: "./gallery/men/3.jpg",
        srcjpg_small: "./gallery/men/3-small.jpg",
        alt:"Men"
    }
]

export const imagesOfWedding = [
    {
        srcwebp: "./gallery/wedding/16.webp",
        srcwebp_small: "./gallery/wedding/16-small.webp",
        srcjpg: "./gallery/wedding/16.jpg",
        srcjpg_small: "./gallery/wedding/16-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/17.webp",
        srcwebp_small: "./gallery/wedding/17-small.webp",
        srcjpg: "./gallery/wedding/17.jpg",
        srcjpg_small: "./gallery/wedding/17-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/1.webp",
        srcwebp_small: "./gallery/wedding/1-small.webp",
        srcjpg: "./gallery/wedding/1.jpg",
        srcjpg_small: "./gallery/wedding/1-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/2.webp",
        srcwebp_small: "./gallery/wedding/2-small.webp",
        srcjpg: "./gallery/wedding/2.jpg",
        srcjpg_small: "./gallery/wedding/2-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/3.webp",
        srcwebp_small: "./gallery/wedding/3-small.webp",
        srcjpg: "./gallery/wedding/3.jpg",
        srcjpg_small: "./gallery/wedding/3-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/4.webp",
        srcwebp_small: "./gallery/wedding/4-small.webp",
        srcjpg: "./gallery/wedding/4.jpg",
        srcjpg_small: "./gallery/wedding/4-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/5.webp",
        srcwebp_small: "./gallery/wedding/5-small.webp",
        srcjpg: "./gallery/wedding/5.jpg",
        srcjpg_small: "./gallery/wedding/5-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/6.webp",
        srcwebp_small: "./gallery/wedding/6-small.webp",
        srcjpg: "./gallery/wedding/6.jpg",
        srcjpg_small: "./gallery/wedding/6-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/7.webp",
        srcwebp_small: "./gallery/wedding/7-small.webp",
        srcjpg: "./gallery/wedding/7.jpg",
        srcjpg_small: "./gallery/wedding/7-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/8.webp",
        srcwebp_small: "./gallery/wedding/8-small.webp",
        srcjpg: "./gallery/wedding/8.jpg",
        srcjpg_small: "./gallery/wedding/8-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/9.webp",
        srcwebp_small: "./gallery/wedding/9-small.webp",
        srcjpg: "./gallery/wedding/9.jpg",
        srcjpg_small: "./gallery/wedding/9-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/10.webp",
        srcwebp_small: "./gallery/wedding/10-small.webp",
        srcjpg: "./gallery/wedding/10.jpg",
        srcjpg_small: "./gallery/wedding/10-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/11.webp",
        srcwebp_small: "./gallery/wedding/11-small.webp",
        srcjpg: "./gallery/wedding/11.jpg",
        srcjpg_small: "./gallery/wedding/11-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/12.webp",
        srcwebp_small: "./gallery/wedding/12-small.webp",
        srcjpg: "./gallery/wedding/12.jpg",
        srcjpg_small: "./gallery/wedding/12-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/13.webp",
        srcwebp_small: "./gallery/wedding/13-small.webp",
        srcjpg: "./gallery/wedding/13.jpg",
        srcjpg_small: "./gallery/wedding/13-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/14.webp",
        srcwebp_small: "./gallery/wedding/14-small.webp",
        srcjpg: "./gallery/wedding/14.jpg",
        srcjpg_small: "./gallery/wedding/14-small.jpg",
        alt:"Wedding"
    },
    {
        srcwebp: "./gallery/wedding/15.webp",
        srcwebp_small: "./gallery/wedding/15-small.webp",
        srcjpg: "./gallery/wedding/15.jpg",
        srcjpg_small: "./gallery/wedding/15-small.jpg",
        alt:"Wedding"
    }
]