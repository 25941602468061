import React, { useState } from 'react';
import './Nav.scss';
import './Burger.scss';
import { menu } from '../../../data/Menu';



export default function Nav() {
    const [toggle,setToggle] = useState(false);

	const handleToggle = () => {
		setToggle(!toggle);
	  };


   return(
   <div>
   <nav className={toggle ? "nav nav-active" : "nav"}>
        <div className="wrapper">
            <ul className="nav-list">
                {menu.map((item,index) => (
                    <li key={index} className={(item.en === 'contact' ? 'btn-contact' : "")+" nav__item"}>
                        <a  onClick={handleToggle} href={"#" + item.en}>{item.cs}</a>
                    </li>
                ))}
            </ul>
        </div>
   </nav>
        <div className={toggle ? "burger toggle" : "burger"} onClick={handleToggle}>
			<div className="line1 burger__line"></div>
			<div className="line2 burger__line"></div>
			<div className="line3 burger__line"></div>
		</div>
   </div>
   );
    
} 

