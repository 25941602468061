import React from 'react';
import './IntroImgGrid.scss';
import Image from 'react-image-webp';


export default function IntroImgGrid(props){

    const small = props.smallImage ? '-small' : '';


return (
    <section id="intro-img-grid" className="intro-img-grid">
        <Image data-aos="fade-up" id="introImg1" className="intro-img" src={`./images/introImg1${small}.jpg`} webp={`./images/introImg1${small}.webp`} alt="introImg1" />
        <Image data-aos="fade-up" id="introImg2" className="intro-img" src={`./images/introImg2${small}.jpg`} webp={`./images/introImg2${small}.webp`} alt="introImg2" />
        <Image data-aos="fade-up" id="introImg3" className="intro-img" src={`./images/introImg3${small}.jpg`} webp={`./images/introImg3${small}.webp`} alt="introImg3" />
        <Image data-aos="fade-up" id="introImg4" className="intro-img" src={`./images/introImg4${small}.jpg`} webp={`./images/introImg4${small}.webp`} alt="introImg4" />
    </section>
); 
}