import React from 'react';
import './Header.scss';
import Nav from '../Menu/Nav/Nav';

export default function Header() {
    

    return (
        <header className="header">
            <Nav/>
        </header>
    );
}

