import React from 'react';
import './Footer.scss';


export default function Footer() {
    

    return (
        <div className="footer">
            <p>
                ©RM Salon | All rights reserved | Designed By: Daniel Štefák
            </p>
        </div>
    );
}